import React from 'react';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Packages from './Components/Packages';
import PageTitle from '../../ReuseableComponents/PageTitle';
import SmallPackages from './Components/SmallPackages';
import AddOn from './Components/AddOn';

export default function DecorPackages() {
	const theme = useTheme();
	return (
		<Grid
			container
			spacing={4}
			sx={{
				backgroundColor: theme.palette.primaryBlue.main,
				padding: 3,
			}}>
			<PageTitle
				pageHeader='Decor Packages'
				pageSubheader='Impress Your Guests'
			/>
			<Packages />
			<SmallPackages />
			<AddOn />
		</Grid>
	);
}
