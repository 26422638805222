// src/components/NavBar.js
import React, { useState } from 'react';
import {
	AppBar,
	Toolbar,
	IconButton,
	Button,
	Box,
	Drawer,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import logo from './Assets/Logo/AnsomEvents.svg';

const Navbar = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	return (
		<>
			<AppBar
				component='header'
				position='static'
				sx={{ background: '#f2f9ff', color: 'black' }}>
				<Toolbar>
					<Grid
						container
						sx={{
							width: '100%', // Ensures the container spans the full width
							margin: 0, // Resets any default margins
							justifyContent: { xs: 'space-between', md: 'space-evenly' },
							alignItems: 'center',
						}}>
						{/* Logo Section */}
						<Grid item size={{ xs: 6, sm: 4, md: 2 }}>
							<Box
								component='img'
								src={logo}
								alt='Logo'
								sx={{ width: '100%', height: 'auto' }}
								padding={1}
							/>
						</Grid>

						{/* Menu Section */}
						<Grid
							item
							size={{ md: 7 }}
							sx={{
								display: { xs: 'none', md: 'flex' },
								justifyContent: 'center',
								fontWeight: '500',
							}}>
							<MuiLink
								component={Link}
								to='/'
								underline='hover'
								display='block'
								sx={{ marginInline: '1rem' }}>
								Home
							</MuiLink>
							<MuiLink
								component={Link}
								to='/decorpackages'
								underline='hover'
								display='block'
								sx={{ marginInline: '1rem' }}>
								Decor Packages
							</MuiLink>
							<MuiLink
								component={Link}
								to='/latestspecials'
								underline='hover'
								display='block'
								sx={{ marginInline: '1rem' }}>
								Latest Specials
							</MuiLink>
						</Grid>

						{/* Social Media + Contact Section (Hidden on Tablet and Below) */}
						<Grid
							item
							size={{ md: 3 }}
							sx={{
								display: { xs: 'none', md: 'flex' },
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}>
							{/* <Box sx={{ marginRight: '2rem' }}>
								<IconButton href='https://facebook.com' target='_blank'>
									<FacebookIcon sx={{ fontSize: '35px', color: '#7096CE' }} />
								</IconButton>
								<IconButton href='https://instagram.com' target='_blank'>
									<InstagramIcon
										sx={{
											fontSize: '35px',
											color: '#7096CE',
										}}
									/>
								</IconButton>
							</Box> */}
							<Button
								component={Link}
								variant='outlined'
								to='/contact'
								sx={{
									borderColor: '#7096CE',
									color: '#7096CE',
									textTransform: 'none',
								}}>
								Contact Us
							</Button>
						</Grid>
						{/* Hamburger Menu for Medium and Below */}
						<Grid
							item
							sx={{
								display: { xs: 'flex', md: 'none' },
								justifyContent: 'flex-end',
							}}>
							<IconButton onClick={handleDrawerToggle}>
								<MenuIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			{/* Side Drawer for Medium Screens and Below */}
			<Drawer anchor='right' open={drawerOpen} onClose={handleDrawerToggle}>
				<Box
					sx={{ width: 250, padding: '2rem' }}
					role='presentation'
					onClick={handleDrawerToggle}>
					<MuiLink
						component={Link}
						to='/'
						underline='hover'
						display='block'
						sx={{ marginInline: '1rem' }}>
						Home
					</MuiLink>
					<MuiLink
						component={Link}
						to='/decorpackages'
						underline='hover'
						display='block'
						sx={{ marginInline: '1rem' }}>
						Decor Packages
					</MuiLink>
					<MuiLink
						component={Link}
						to='/latestspecials'
						underline='hover'
						display='block'
						sx={{ marginInline: '1rem' }}>
						Latest Specials
					</MuiLink>
				</Box>
			</Drawer>
		</>
	);
};

export default Navbar;
