import React from 'react';
import oneSide from './../../../Assets/Photos/Packages/oneSide.jpg';
import lShape from './../../../Assets/Photos/Packages/lShape.jpg';
import fullSize from './../../../Assets/Photos/Packages/fullSize.jpg';
import {
	BalloonGarlandContainer,
	BalloonGarlandTitle,
	BalloonGarlandOptionsContainer,
	BalloonOption,
	BalloonOptionTitle,
	BalloonOptionImage,
	BalloonOptionPrice,
} from '../DecorPackagesStyles';
import Price from '../../../ReuseableComponents/Price';

export default function BalloonGarland() {
	const balloonImages = [
		{ title: 'One Side', image: oneSide, price: '60' },
		{ title: 'L-Shape', image: lShape, price: '90' },
		{ title: '18ft Garland', image: fullSize, price: '135' },
	];
	return (
		<BalloonGarlandContainer>
			<BalloonGarlandTitle variant='h5' component='h2'>
				Balloon Garland to add
			</BalloonGarlandTitle>
			<BalloonGarlandOptionsContainer>
				{balloonImages.map((pkg, index) => (
					<BalloonOption key={index} container spacing={1}>
						<BalloonOptionTitle>{pkg.title}</BalloonOptionTitle>
						<BalloonOptionImage src={pkg.image} />
						<Price price={pkg.price} />
					</BalloonOption>
				))}
			</BalloonGarlandOptionsContainer>
		</BalloonGarlandContainer>
	);
}
